import { I18nextContext } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { usePageSalesdataCs } from '../hooks/cs/page-sales.metadata';
import { usePageSalesdataEn } from '../hooks/en/page-sales.metadata.en';

export function PageSalesMetadata(): any {
  const { language } = React.useContext(I18nextContext);
  let data;
  if (language == 'cs') {
    data = usePageSalesdataCs();
  } else {
    data = usePageSalesdataEn();
  }
  return data;
}
